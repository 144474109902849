import { createContext, useContext } from 'react';
import useAuthHook from './hooks/AuthHook';
import { AuthContextInterface } from '../types/types';
// initialize auth context. Export for testing
export const AuthContext = createContext<AuthContextInterface>({
    user: null,
    project: null,
    group: null,
    isNewUser: false,
    setIsNewUser: () => {},
    subscription: null,
    loading: true,
    signOut: async () => {},
    postLogin: () => {},
    podcastChannel: null,
    fetchAndUpdatePodcastChannel: () => {},
    rssIntegrationSettings: undefined,
    hubspotIntegrationSettings: undefined,
    narrationSettings: undefined,
    allNarrationSettings: undefined,
    hosts: [],
    fetchAndUpdateNarrationSettings: () => {},
    fetchAndUpdateCmsIntegrationSettings: () => {},
    fetchAndUpdateProject: () => {},
    fetchAndUpdateGroupAfterCreditUpdate: () => {},
    fetchAndUpdateHosts: () => {},
    registerPosthogEventWithAuthProps: () => {},
    isLoadingNarrationUsage: false,
    updateUserDisplayName: () => {},
    fetchAndUpdateGroup: async () => {},
    suggestedRssFeed: undefined,
    setCoreWorkspaceDocuments: (uid?: string) => {}
});
// hook that we can use anywhere in the app
export const useAuth = () => useContext(AuthContext);

// this will be the wrapper of the whole auth context that will be used in _app.js
export function AuthProvider({ children }: any) {
    const auth = useAuthHook();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
