import axios, { AxiosResponse } from 'axios';
import {
    NarrationInterface,
    FirestoreDocumentIdType,
    TextNarrationInterface,
    UrlType,
    NarrationRequestCountMapType,
    NarrationRequestActionInterface,
    GroupInterface,
    ProjectInterface,
    TextNarrationWithoutDefaultsType,
    NarrationPostPayloadInterface,
    StatusEnum,
    NarrationBlocksPayloadInterface,
    NarrationBlockInterface,
    NarrationSettingsWordListType,
    NarrationMethodEnum,
    AudioCreationDataInterface,
    isAudioCreationDataInterface
} from '../types/types';
import { captureException } from '@sentry/nextjs';
import { registerPosthogEvent } from './postHogEventHelpers';
import { EVENT_NAME, USER_PROPERTY_NAME } from '../utils/constants/postHogConstants';
import { BLOCKS_NARRATION_DEFAULTS, TEXT_NARRATION_DEFAULTS } from '../utils/constants/payloadConstants';
import { formatSentryError } from './telemetryHelpers';
import { createNarrationDocument, getNarration, requestDeletion } from './firestoreHelpers';
import { removeEmptyBlocks } from './blocksHelpers';
import { getNarrationTypeFromBlocks } from './narrationTypeHelpers';
import { ADAURIS_SQUARE_LOGO, DRAFT_ICON } from '../utils/constants/publicAssetsConstants';
import { isUrl } from './urlHelpers';

export const deleteNarration = async (
    narration: NarrationInterface,
    group: GroupInterface | null,
    project: ProjectInterface | null,
    mutateNarrations: () => void // * should be passed from globalNarrations swr context
) => {
    registerPosthogEvent(EVENT_NAME.deleteNarration, {
        ...narration,
        group,
        project
    });
    try {
        await requestDeletion(narration.id);
        mutateNarrations();
    } catch (err) {
        throw err;
    }
    return;
};

export const createAudioFromText = async (payload: TextNarrationInterface): Promise<AudioCreationDataInterface> => {
    try {
        const response = await axios.post(`${process.env.TTS_AUDIO_CREATION_SERVICE}/v4/audio/text-to-audio`, payload, {
            timeout: 300000 // Timeout in milliseconds (5 minutes)
        });
        if (!isAudioCreationDataInterface(response.data)) {
            throw Error('No audioUrl returned for new text audio');
        }
        return response.data;
    } catch (error) {
        const { message, cause } = formatSentryError('Failed to generate audio from text', 'createAudioFromText', error);
        captureException(new Error(message, cause));
        throw error;
    }
};

export const createAudioFromBlocks = async (payload: NarrationBlocksPayloadInterface): Promise<AudioCreationDataInterface> => {
    try {
        const response = await axios.post('/api/v2/tts/blocks/post', payload, {
            timeout: 300000 // Timeout in milliseconds (5 minutes)
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createTextNarration = async (
    ttsServicePartialPayload: TextNarrationWithoutDefaultsType,
    projectId: FirestoreDocumentIdType,
    sourceUrl: UrlType,
    podcastChannelId: FirestoreDocumentIdType | undefined,
    mutateNarrations: () => void // * should be passed from globalNarrations swr context
): Promise<FirestoreDocumentIdType> => {
    const ttsServicePayload: TextNarrationInterface = { ...ttsServicePartialPayload, ...TEXT_NARRATION_DEFAULTS };

    let newNarrationId: FirestoreDocumentIdType;
    try {
        const ttsAudioCreationResponse = await createAudioFromText(ttsServicePayload);

        const narrationDocPayload: NarrationPostPayloadInterface = {
            projectId,
            audio: ttsAudioCreationResponse.audioUrl,
            narrationMethod: TEXT_NARRATION_DEFAULTS.narrationMethod,
            sourceUrl,
            title: ttsServicePayload.titleText,
            sourceType: ttsServicePayload.narrationType,
            publishDate: new Date().toISOString(),
            stats: ttsAudioCreationResponse.charStats,
            duration: ttsAudioCreationResponse.duration,
            // * NOTE THE FOLLOWING VALUES ARE HARD CODED FOR NOW
            narrationSettings: null,
            version: 2,
            sourceLanguage: 'en',
            siteName: null,
            image: null,
            status: StatusEnum.Success
        };

        // * add the podcast channel id if the user has a podcast channel
        if (podcastChannelId) {
            narrationDocPayload.podcastChannelId = podcastChannelId;
        }

        newNarrationId = await createNarrationDocument(narrationDocPayload, mutateNarrations);
    } catch (error) {
        registerPosthogEvent(EVENT_NAME.requestNarrationCreation, {
            narrationMethod: TEXT_NARRATION_DEFAULTS.narrationMethod,
            narrationType: TEXT_NARRATION_DEFAULTS.narrationType,
            narrationSuccess: false
        });
        throw error;
    }

    // * keep logging out of try block in case it throws anything
    registerPosthogEvent(EVENT_NAME.requestNarrationCreation, {
        narrationMethod: TEXT_NARRATION_DEFAULTS.narrationMethod,
        narrationType: TEXT_NARRATION_DEFAULTS.narrationType,
        narrationSuccess: true,
        $set_once: {
            [USER_PROPERTY_NAME.hasGeneratedATextNarration]: true
        }
    });

    return newNarrationId;
};

export const createBlocksNarration = async (
    blocks: NarrationBlockInterface[],
    userId: FirestoreDocumentIdType,
    groupId: FirestoreDocumentIdType,
    narrationMethod: NarrationMethodEnum,
    wordList?: NarrationSettingsWordListType
): Promise<AudioCreationDataInterface> => {
    blocks = removeEmptyBlocks(blocks);
    const narrationType = getNarrationTypeFromBlocks(blocks);

    const payload: NarrationBlocksPayloadInterface = {
        ...BLOCKS_NARRATION_DEFAULTS,
        narrationType,
        narrationMethod,
        blocks,
        userId,
        groupId,
        wordList
    };

    let ttsAudioCreationResponse: AudioCreationDataInterface;

    try {
        ttsAudioCreationResponse = await createAudioFromBlocks(payload);
    } catch (error) {
        registerPosthogEvent(EVENT_NAME.requestNarrationCreation, {
            narrationMethod: payload.narrationMethod,
            narrationType: payload.narrationType,
            narrationSuccess: false
        });
        throw error;
    }

    // * keep logging out of try block in case it throws anything
    registerPosthogEvent(EVENT_NAME.requestNarrationCreation, {
        narrationMethod: payload.narrationMethod,
        narrationType: payload.narrationType,
        narrationSuccess: true,
        $set_once: {
            [USER_PROPERTY_NAME.hasGeneratedABlocksNarration]: true
        }
    });

    return ttsAudioCreationResponse;
};

export const extractNarrationsFromAxiosResponse = (response: AxiosResponse): NarrationInterface[] => {
    let narrations: NarrationInterface[] | null = response.data;

    // handle null return from status 204
    if (response.status === 204 || !narrations) {
        narrations = [];
    }

    return narrations;
};

export const narrationRequestsReducer = (state: NarrationRequestCountMapType, action: NarrationRequestActionInterface) => {
    switch (action.type) {
        case 'ADD':
            return { ...state, [action.timestamp]: action.title };
        case 'REMOVE':
            // * removes the timestamp obj from the state
            const { [action.timestamp]: removedTimestamp, ...newState } = state;
            return newState;
        default:
            return state;
    }
};

/**
 *
 * @param {NarrationInterface} narration
 * @returns {boolean} isAudioStudioNarration
 */
export const getIsAudioStudioNarration = (narration: NarrationInterface): boolean => {
    return (
        narration.narrationMethod === NarrationMethodEnum.SingleNarrationRequest ||
        narration.narrationMethod === NarrationMethodEnum.SingleTransformedTextRequest ||
        narration.narrationMethod === NarrationMethodEnum.AudioShorts ||
        narration.narrationMethod === NarrationMethodEnum.DailyRundown ||
        narration.narrationMethod === NarrationMethodEnum.FileUpload ||
        narration.narrationMethod === NarrationMethodEnum.TwoSpeakerPodcast ||
        narration.narrationMethod === NarrationMethodEnum.LeadGen
    );
};

// type is an interface so that it is easier to pass props without worrying about the order of the props
export const filterAllNarrationsIntoGroupedArrays = ({
    allNarrations,
    setSuccessAndDraftNarrations,
    setSuccessNarrations,
    setDraftNarrations,
    setAudioStudioNarrations,
    setSucessNarrationsLast30Days
}: {
    allNarrations: NarrationInterface[] | undefined;
    setSuccessAndDraftNarrations: (narrations: NarrationInterface[]) => void;
    setSuccessNarrations: (narrations: NarrationInterface[]) => void;
    setDraftNarrations: (narrations: NarrationInterface[]) => void;
    setAudioStudioNarrations: (narrations: NarrationInterface[]) => void;
    setSucessNarrationsLast30Days: (narrations: NarrationInterface[]) => void;
}) => {
    const _successNarrations = [];
    const _draftNarrations = [];
    const _successAndDraftNarrations = [];
    const _audioStudioNarrations = [];
    const _successNarrationsLast30Days = [];
    // * filter narrations based on status
    const allNarrationsCount = allNarrations?.length;
    if (allNarrationsCount) {
        const date = new Date();
        const thirtyDaysAgoInSeconds = date.setDate(date.getDate() - 30) / 1000;
        for (let i = 0; i < allNarrationsCount; i++) {
            const narration = allNarrations[i];
            // status filters
            if (getIsPublishedNarration(narration.status)) {
                _successNarrations.push(narration);
                _successAndDraftNarrations.push(narration);

                // * the firstore timestamp is stored in either seconds or _seconds
                if (
                    ('_seconds' in narration.creationDate && narration.creationDate._seconds > thirtyDaysAgoInSeconds) ||
                    ('seconds' in narration.creationDate && narration.creationDate.seconds > thirtyDaysAgoInSeconds)
                ) {
                    _successNarrationsLast30Days.push(narration);
                }
            } else if (narration.status === StatusEnum.Draft) {
                _draftNarrations.push(narration);
                _successAndDraftNarrations.push(narration);
            }

            // narration method filters
            if (getIsAudioStudioNarration(narration)) {
                _audioStudioNarrations.push(narration);
            }
        }
    }
    setSuccessAndDraftNarrations(_successAndDraftNarrations);
    setSuccessNarrations(_successNarrations);
    setDraftNarrations(_draftNarrations);
    setAudioStudioNarrations(_audioStudioNarrations);
    setSucessNarrationsLast30Days(_successNarrationsLast30Days);
};

export const getNarrationImage = (narration: NarrationInterface): string => {
    if (narration.image && isUrl(narration.image)) {
        return narration.image;
    } else if (narration.status === StatusEnum.Draft) {
        return DRAFT_ICON;
    } else {
        return ADAURIS_SQUARE_LOGO;
    }
};

export const getNarrationIdFromSharePageUrl = async (sharePageUrl: string) => {
    try {
        const narrationId = sharePageUrl?.split('/').pop();
        if (!narrationId) {
            throw new Error('No narration ID');
        }
        const _narration = await getNarration(narrationId);
        return _narration;
    } catch (error) {
        const { message, cause } = formatSentryError('Error getting narration from share page url', 'getNarrationIdFromSharePageUrl', error);
        captureException(new Error(message, cause));
        throw error;
    }
};

export const getIsPublishedNarration = (narrationStatus: StatusEnum | undefined): boolean => {
    return narrationStatus === StatusEnum.Success || narrationStatus === StatusEnum.Private;
};
