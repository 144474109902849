import { captureException } from '@sentry/nextjs';
import { FirestoreDocumentIdType, NarrationInterface, StatusEnum } from '../types/types';
import { formatSentryMessage } from './telemetryHelpers';
import { getIsPublishedNarration } from './narrationHelpers';

export const isGoogleDoc = (urlInput: string) => {
    let urlObj;
    try {
        urlObj = new URL(urlInput);
    } catch (e) {
        return false;
    }

    const host = urlObj.hostname.toLowerCase();
    const docHost = 'docs.google.com';

    if (host === docHost) {
        return true;
    }

    return false;
};

export const isUrl = (urlInput: string) => {
    try {
        new URL(urlInput);
    } catch (_) {
        return false;
    }

    return true;
};

export const isInvalidUrl = (url: string) => {
    return !isUrl(url);
};

export const isEbook = (url: string) => {
    return url.lastIndexOf('.epub') > 0;
};

export const isPdf = (url: string) => {
    return url.lastIndexOf('.pdf') > 0;
};

export const isPdfBlocked = (url: string, isDisabled?: boolean) => {
    return !!isDisabled && isPdf(url);
};

export const isMissingHttp = (url: string) => {
    return !!url && !url.includes('http://') && !url.includes('https://');
};

export const isDoi = (url: string) => {
    return url.includes('/doi') || url.includes('doi:');
};

export const isPodcastLink = (url: string) => {
    return url.includes('podcasts.ad-auris');
};

export const isAdAurisLink = (url: string) => {
    return url.includes('play.ad-auris');
};

export const isGmailLink = (url: string) => {
    return url.includes('mail.google.com');
};

export const isRedditLink = (url: string) => {
    return url.includes('reddit.com');
};

export const isYoutubeLink = (url: string) => {
    return url.includes('youtube.com');
};

export const isLinkedInLink = (url: string) => {
    return url.includes('linkedin.com');
};

export const isTwitterLink = (url: string) => {
    return url.includes('twitter.com');
};

export const normalizeStringForUrl = (text: string) => {
    return text
        .replace(/[ ]/g, '-')
        .replace(/[^a-zA-Z0-9\-]/g, '')
        .toLowerCase();
};

export const formatTitleForUrl = (title: string) => {
    let normalizedTitle = normalizeStringForUrl(title);
    if (normalizedTitle.length > 20) {
        normalizedTitle = normalizedTitle.substring(0, 19);
    }
    return normalizedTitle;
};

export const getPublicNarrationShareUrl = (
    projectId: FirestoreDocumentIdType | undefined | null,
    narrationId: FirestoreDocumentIdType | undefined | null,
    narrationStatus: StatusEnum | undefined
): string | null => {
    const baseNarrationWidgetUrl = process.env.NEXT_PUBLIC_BASE_NARRATION_WIDGET_URL;

    if (baseNarrationWidgetUrl && getIsPublishedNarration(narrationStatus) && projectId && narrationId) {
        return `${baseNarrationWidgetUrl}/v2/widget/${projectId}/${narrationId}`;
    } else {
        if (!baseNarrationWidgetUrl) {
            const message = formatSentryMessage('Missing NEXT_PUBLIC_BASE_NARRATION_WIDGET_URL', 'getPublicNarrationShareUrl');
            captureException(new Error(message));
        }
        // * we want to return null if the narration is not ready to be shared.
        // * It can be valid that project, narrationDocId, or narrationStatus are not valid yet when this function is called.
        return null;
    }
};

export const getPublicNarrationSocialMediaUrl = (platformUrl: string, shareUrlParam: string | null) => {
    if (shareUrlParam) {
        return encodeURI(platformUrl + shareUrlParam);
    } else return '';
};
