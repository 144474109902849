// * This container allows us to access context variables within _appMultiProvider level

import { AppProps } from 'next/app';
import GlobalNarrationProvider from '../../context/hooks/GlobalNarrationHook';
import AppComponentContainer from './_appComponentContainer';
import { VoiceDataProvider } from '../../context/hooks/VoiceDataHook';

function AppGlobalNarrationProviderContainer(appProps: AppProps) {
    return (
        <VoiceDataProvider>
            <GlobalNarrationProvider>
                <AppComponentContainer {...appProps} />
            </GlobalNarrationProvider>
        </VoiceDataProvider>
    );
}

export default AppGlobalNarrationProviderContainer;
