import React, { useMemo } from 'react';
import { useColorModeValue, Flex, Button, useBreakpointValue } from '@chakra-ui/react';
import LinkedLogo from '../Logo/LinkedLogo/LinkedLogo';
import { NAVBAR_HEIGHT } from '../../utils/constants/stylingConstants';
import { openNewTab } from '../../helpers/tabHelpers';
import DarkModeLogo from '../Logo/DarkModeLogo/DarkModeLogo';
import { useRouter } from 'next/router';
import { ALL_ROUTES } from '../../types/types';

interface NavBarProps {
    showProductDemoButton?: boolean;
}

export default function NavBar({ showProductDemoButton = false }: NavBarProps) {
    const router = useRouter();
    const handleProductDemoLinkClick = () => {
        openNewTab('https://calendly.com/tina-haertel/productdemo?hide_gdpr_banner=1');
    };

    const utmParamsOnLogo = useMemo(() => {
        if (router.pathname.includes(ALL_ROUTES.MOTIVATION_2025)) {
            return '?utm_campaign=2025motivation&utm_source=dashboard&utm_content=logo';
        } else {
            return '';
        }
    }, [router]);

    return (
        <Flex w="full" bgColor={'black'} px={{ base: 2, sm: 4 }} borderRadius="md" height={NAVBAR_HEIGHT} position={'fixed'} zIndex={99}>
            <Flex alignItems="center" justifyContent="space-between" w={'full'}>
                <Flex>
                    {<LinkedLogo icon={<DarkModeLogo />} href={(process.env.NEXT_PUBLIC_SPLASH_PAGE_BASE_URL as string) + utmParamsOnLogo} />}
                </Flex>
                <Flex gap={{ base: 1, sm: 4, md: 16 }}>
                    <Flex gap={2}>
                        <Button
                            onClick={handleProductDemoLinkClick}
                            variant={'solid'}
                            display={useBreakpointValue({ base: 'none', md: 'flex' })}
                            visibility={showProductDemoButton ? 'visible' : 'hidden'}
                        >
                            Book Product Demo
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
