import { invertObject } from '../../helpers/objectHelpers';

export const INITIAL_BROWSE_FLAG = true;
export const INITIAL_PODCAST_SYNC_FLAG = true;
export const INITIAL_TEXT_NARRATION_FLAG = true;
export const INITIAL_PDF_SUPPORT_FLAG = true;
export const INITIAL_AUDIO_DOWNLOAD_FLAG = true;
export const INITIAL_UNLIMITED_NARRATIONS_FLAG = true;

export enum POSTHOG_FEATURE_FLAGS {
    PAYMENT_PORTAL_BYPASS = 'payment-portal-bypass',
    SHOW_PUBLICATION_URL_ONBOARDING_STEP = 'show-publication-url-onboarding-step',
    ALLOW_WHITE_GLOVE_NARRATION_CREATION = 'allow-white-glove-narration-creation',
    ALLOW_SECTIONED_NARRATIONS_FORM = 'allow-sectioned-narration-form',
    ALLOW_YOUTUBE_INTEGRATION_V1 = 'youtube-integration-v1',
    HIDE_AUTO_NARRATIONS = 'hide-auto-narrations',
    SHOW_WORDSMITH_PARAMETERS = 'show-wordsmith-parameters',
    SHOW_SHARE_NARRATION_BUTTON = 'show-share-narration-button',
    ALLOW_TRANSFORMED_AUDIO = 'allow-transformed-audio',
    ALLOW_UNLIMITED_AUDIO_STUDIO = 'allow-unlimited-audio-studio',
    ENABLE_FIRST_USE_TUTORIAL_FLOW = 'enable-first-use-tutorial-flow',
    HAS_DISTRIBUTED_WIDGET = 'has-distributed-widget',
    HAS_SHARED_ON_SOCIAL_MEDIA = 'has-shared-on-social-media',
    HAS_DOWNLOADED_AUDIO = 'has-downloaded-audio',
    UNLOCK_ALL_ANALYTICS = 'unlock-all-analytics',
    SHOW_REWRITE_SCRIPT = 'show-rewrite-script',
    ALLOW_SONIC_IDENTITY = 'allow-sonic-identity',
    IS_ADAURIS_ADMIN = 'is-adauris-admin',
    UNLOCK_ENTERPRISE_FEATURES = 'unlock-enterprise-features',
    ALLOW_UNLIMITED_HOSTS = 'allow-unlimited-hosts',
    ALLOW_CUSTOM_TEMPLATES = 'allow-custom-templates',
    ALLOW_LEAD_GEN = 'allow-lead-gen'
}

// * these values need to reflect what is being used in and Stripe
export enum PLAN_VALUE {
    FREE = 'FREE',
    BASIC = 'BASIC',
    PROFESSIONAL = 'PROFESSIONAL',
    ENTERPRISE = 'ENTERPRISE',
    ONBOARDING_PACKAGE = 'ONBOARDING_PACKAGE'
}

export enum Frequencies {
    month = 'month',
    year = 'year',
    oneOff = 'oneOff'
}

// * to add new tiers, simply add the new tier to the enum and add the new tier to the switch statement in the getPlanName function
export const PLAN_ACCESS_LEVEL = {
    TUTORIAL: 0,
    [PLAN_VALUE.FREE]: 0.5,
    [PLAN_VALUE.BASIC]: 1,
    [PLAN_VALUE.PROFESSIONAL]: 2,
    [PLAN_VALUE.ENTERPRISE]: 3, // * currently tied to bypass-payment-portal and unlock-enterprise-features Posthog feature flags
    [PLAN_VALUE.ONBOARDING_PACKAGE]: 3.5
};

export const PAYMENT_TIER_ACCESS_LEVEL_NAMES: Record<number, string> = invertObject(PLAN_ACCESS_LEVEL);

export enum PAYWALLED_FEATURES {
    DOWNLOAD_AUDIO = 'DOWNLOAD_AUDIO',
    UPLOAD_AUDIO = 'UPLOAD_AUDIO',
    MANUAL_SAVE_DRAFT = 'MANUAL_SAVE_DRAFT',
    DELETE_NARRATION = 'DELETE_NARRATION',
    INTROS_AND_OUTROS = 'INTROS_AND_OUTROS',
    EMBED_PLAYER = 'EMBED_PLAYER',
    PLAYER_CUSTOMIZATION = 'PLAYER_CUSTOMIZATION',
    LEXICON = 'LEXICON',
    ANALYTICS = 'ANALYTICS',
    PODCAST_DISTRIBUTION = 'PODCAST_DISTRIBUTION',
    YOUTUBE_DISTRIBUTION = 'YOUTUBE_DISTRIBUTION',
    AUDIO_STUDIO_PAGE = 'AUDIO_STUDIO_PAGE',
    AUTO_NARRATIONS_PAGE = 'AUTO_NARRATIONS_PAGE',
    AUDIO_CATALOGUE_PAGE = 'AUDIO_CATALOGUE_PAGE',
    DISTRIBUTION_PAGE = 'DISTRIBUTION_PAGE',
    ANALYTICS_PAGE = 'ANALYTICS_PAGE',
    PRONUNCIATION_LIBRARY_PAGE = 'PRONUNCIATION_LIBRARY_PAGE',
    PAYMENTS = 'PAYMENTS',
    AI_AUDIO = 'AI_AUDIO',
    UNINTRUSIVE_PAGE_LEAVE = 'UNINTRUSIVE_PAGE_LEAVE',
    AUDIO_STUDIO_NARRATIONS = 'AUDIO_STUDIO_NARRATIONS',
    PREMIUM_VOICES = 'PREMIUM_VOICES',
    STUDIO_DISTRIBUTION_TAB_SHAREABLE_LINK = 'STUDIO_DISTRIBUTION_TAB_SHAREABLE_LINK',
    STUDIO_DISTRIBUTION_TAB_EMBEDDABLE_PLAYER = 'STUDIO_DISTRIBUTION_TAB_EMBEDDABLE_PLAYER',
    STUDIO_DISTRIBUTION_TAB_PODCASTS = 'STUDIO_DISTRIBUTION_TAB_PODCASTS',
    STUDIO_DISTRIBUTION_TAB_YOUTUBE = 'STUDIO_DISTRIBUTION_TAB_YOUTUBE',
    STUDIO_DISTRIBUTION_TAB_DOWNLOAD = 'STUDIO_DISTRIBUTION_TAB_DOWNLOAD',
    STUDIO_DISTRIBUTION_TAB_SOCIAL_MEDIA = 'STUDIO_DISTRIBUTION_TAB_SOCIAL_MEDIA',
    RESUME_TUTORIAL = 'RESUME_TUTORIAL',
    HIDE_ONBOARDING_CTAS = 'HIDE_ONBOARDING_CTAS',
    SHOW_INTEGRATIONS_CTAS = 'SHOW_INTEGRATIONS_CTAS',
    NO_CHAR_CAP_NARRATIONS = 'NO_CHAR_CAP_NARRATIONS',
    EDIT_NARRATION = 'EDIT_NARRATION',
    SHARE_PAGE_CUSTOMIZATION = 'SHARE_PAGE_CUSTOMIZATION',
    IMAGE_UPLOAD = 'IMAGE_UPLOAD',
    INTEGRATIONS_PAGE = 'INTEGRATIONS_PAGE',
    RSS_INTEGRATION = 'RSS_INTEGRATION',
    HUBSPOT_INTEGRATION = 'HUBSPOT_INTEGRATION',
    FREE_EXPLORE = 'FREE_EXPLORE',
    BRANDING_PAGE = 'BRANDING_PAGE',
    BRANDING = 'BRANDING'
}

// map each feature name to their required plan access level number
export const REQUIRED_PLAN_FOR_PREMIUM_FEATURES: Record<PAYWALLED_FEATURES, number> = {
    [PAYWALLED_FEATURES.AUDIO_STUDIO_NARRATIONS]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.STUDIO_DISTRIBUTION_TAB_SHAREABLE_LINK]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.STUDIO_DISTRIBUTION_TAB_PODCASTS]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.AUDIO_STUDIO_PAGE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE], // * This displays the page. narration cap is handled by isAtCapacityOnAudioStudio
    [PAYWALLED_FEATURES.FREE_EXPLORE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.AUDIO_CATALOGUE_PAGE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.ANALYTICS_PAGE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.UNINTRUSIVE_PAGE_LEAVE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.NO_CHAR_CAP_NARRATIONS]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.SHOW_INTEGRATIONS_CTAS]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.HIDE_ONBOARDING_CTAS]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.RESUME_TUTORIAL]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.STUDIO_DISTRIBUTION_TAB_EMBEDDABLE_PLAYER]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.STUDIO_DISTRIBUTION_TAB_YOUTUBE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.STUDIO_DISTRIBUTION_TAB_SOCIAL_MEDIA]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.AI_AUDIO]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.MANUAL_SAVE_DRAFT]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.AUTO_NARRATIONS_PAGE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE], // * This displays the page. Narration cap is handled by cloud functions
    [PAYWALLED_FEATURES.INTEGRATIONS_PAGE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.DISTRIBUTION_PAGE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.PAYMENTS]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.PRONUNCIATION_LIBRARY_PAGE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.IMAGE_UPLOAD]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.BRANDING_PAGE]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.PREMIUM_VOICES]: PLAN_ACCESS_LEVEL[PLAN_VALUE.FREE],
    [PAYWALLED_FEATURES.EDIT_NARRATION]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.STUDIO_DISTRIBUTION_TAB_DOWNLOAD]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.UPLOAD_AUDIO]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.EMBED_PLAYER]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.DELETE_NARRATION]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.INTROS_AND_OUTROS]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.PLAYER_CUSTOMIZATION]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.ANALYTICS]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.PODCAST_DISTRIBUTION]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.YOUTUBE_DISTRIBUTION]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.SHARE_PAGE_CUSTOMIZATION]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.DOWNLOAD_AUDIO]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.LEXICON]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.RSS_INTEGRATION]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.HUBSPOT_INTEGRATION]: PLAN_ACCESS_LEVEL[PLAN_VALUE.BASIC],
    [PAYWALLED_FEATURES.BRANDING]: PLAN_ACCESS_LEVEL[PLAN_VALUE.PROFESSIONAL]
};

export const DEFAULT_IS_FEATURE_PAYWALLED_CONTEXT_VALUES = {
    DOWNLOAD_AUDIO: undefined,
    UPLOAD_AUDIO: undefined,
    DELETE_NARRATION: undefined,
    MANUAL_SAVE_DRAFT: undefined,
    INTROS_AND_OUTROS: undefined,
    EMBED_PLAYER: undefined,
    PLAYER_CUSTOMIZATION: undefined,
    LEXICON: undefined,
    ANALYTICS: undefined,
    PODCAST_DISTRIBUTION: undefined,
    YOUTUBE_DISTRIBUTION: undefined,
    AUDIO_STUDIO_PAGE: undefined,
    AUTO_NARRATIONS_PAGE: undefined,
    AUDIO_CATALOGUE_PAGE: undefined,
    DISTRIBUTION_PAGE: undefined,
    ANALYTICS_PAGE: undefined,
    PAYMENTS: undefined,
    PRONUNCIATION_LIBRARY_PAGE: undefined,
    AI_AUDIO: undefined,
    UNINTRUSIVE_PAGE_LEAVE: undefined,
    AUDIO_STUDIO_NARRATIONS: undefined,
    PREMIUM_VOICES: undefined,
    STUDIO_DISTRIBUTION_TAB_SHAREABLE_LINK: undefined,
    STUDIO_DISTRIBUTION_TAB_EMBEDDABLE_PLAYER: undefined,
    STUDIO_DISTRIBUTION_TAB_PODCASTS: undefined,
    STUDIO_DISTRIBUTION_TAB_YOUTUBE: undefined,
    STUDIO_DISTRIBUTION_TAB_DOWNLOAD: undefined,
    STUDIO_DISTRIBUTION_TAB_SOCIAL_MEDIA: undefined,
    RESUME_TUTORIAL: undefined,
    HIDE_ONBOARDING_CTAS: undefined,
    SHOW_INTEGRATIONS_CTAS: undefined,
    NO_CHAR_CAP_NARRATIONS: undefined,
    EDIT_NARRATION: undefined,
    SHARE_PAGE_CUSTOMIZATION: undefined,
    IMAGE_UPLOAD: undefined,
    INTEGRATIONS_PAGE: undefined,
    RSS_INTEGRATION: undefined,
    HUBSPOT_INTEGRATION: undefined,
    FREE_EXPLORE: undefined,
    BRANDING_PAGE: undefined,
    BRANDING: undefined
};
