import { signOut as fSignOut, onAuthStateChanged, User, UserCredential, getAdditionalUserInfo, updateProfile } from 'firebase/auth';
import { auth } from '../../config/auth/firebase';
import { useEffect, useState } from 'react';
import {
    ALL_ROUTES,
    AuthContextInterface,
    GroupInterface,
    HostInterface,
    HubspotIntegrationSettings,
    NarrationSettingsInterface,
    PodcastChannelInterface,
    ProjectInterface,
    RSSIntegrationSettings,
    SubscriptionInterface
} from '../../types/types';
import { deleteAuthCookie, formatAuthCookie, checkIfPromoCookieExists, getSingleCookie } from '../../helpers/cookieHelpers';
import * as Sentry from '@sentry/nextjs';
import { captureException } from '@sentry/nextjs';
import { formatSentryError, formatSentryMessage, initTelemetryUser } from '../../helpers/telemetryHelpers';
import posthog from 'posthog-js';
import { EVENT_NAME } from '../../utils/constants/postHogConstants';
import { registerPosthogEvent } from '../../helpers/postHogEventHelpers';
import { getOrCreateCoreWorkspaceDocuments, getSubscription } from '../../helpers/accountHelpers';
import { getPodcastChannel } from '../../helpers/podcastHelpers';
import { getGroupByUid } from '../../helpers/groupHelpers';
import { getProjectByGroupId } from '../../helpers/projectHelpers';
import {
    checkIfHubspotDataCookieExists,
    filterHubspotIntegrationSettingsFromCmsIntegrationSettings,
    filterRssIntegrationSettingsFromCmsIntegrationSettings,
    getAllCmsIntegrationSettingsWithNarrationSettingsByProject
} from '../../helpers/cmsHelpers';
import { getAllNarrationSettingsByGroup, getAndSetNarrationSettingsForGroup } from '../../helpers/narrationSettingsHelpers';
import { useRouter } from 'next/router';
import { isDeepEqual } from '../../helpers/objectHelpers';
import { updateSuggestedRssFeed } from '../../helpers/rssFeedHelper';
import { registerGoogleAnalyticsSignupEvent, registerTwitterEvent } from '../../helpers/marketingEventHelpers';
import { identifyPosthogGroup } from '../../helpers/posthogIdentifyHelpers';
import { getHostsByGroupId } from '../../helpers/hostsHelpers';

// firebase functions are defined here
export default function useAuthHook(): AuthContextInterface {
    // TODO update all initial null states to be undefined, following the logic used for narrationSettings
    const [user, setUser] = useState<User | null>(null);
    const [project, setProject] = useState<ProjectInterface | null>(null);
    const [group, setGroup] = useState<GroupInterface | null>(null);
    const [isNewUser, setIsNewUser] = useState<boolean>(false);
    const [subscription, setSubscription] = useState<SubscriptionInterface | null>(null);
    const [loading, setLoading] = useState(true);
    const [podcastChannel, setPodcastChannel] = useState<PodcastChannelInterface | null | undefined>(undefined); // * null = it has been checked and doesn't exist. undefined = not checked yet
    const [rssIntegrationSettings, setRssIntegrationSettings] = useState<RSSIntegrationSettings[] | undefined>(undefined);
    const [hubspotIntegrationSettings, setHubspotIntegrationSettings] = useState<HubspotIntegrationSettings[] | undefined>(undefined);
    const [narrationSettings, setNarrationSettings] = useState<NarrationSettingsInterface | null | undefined>(undefined); // * null = it has been checked and doesn't exist. undefined = not checked yet
    const [allNarrationSettings, setAllNarrationSettings] = useState<NarrationSettingsInterface[] | null | undefined>(undefined); // * null = it has been checked and doesn't exist. undefined = not checked yet
    const [hosts, setHosts] = useState<HostInterface[] | null | undefined>(undefined); // * null = it has been checked and doesn't exist. undefined = not checked yet
    const [isLoadingNarrationUsage, setIsLoadingNarrationUsage] = useState(true); // * used when fetching group after a credit update. If true, it means we are waiting for the group to update, so this is true by default since we initialize without group
    const [suggestedRssFeed, setSuggestedRssFeed] = useState<string | undefined | null>(undefined); // * used to store a detected rss feed from the group.siteURl that hasn't been saved yet, if any. null = it has been checked and doesn't exist. undefined = not checked yet
    const router = useRouter();

    // * pass passedUser if user state was just recently updated to remove chance of race condition against state update
    const getAndSetSubscription = async (passedUser: User | null) => {
        if (!passedUser) {
            if (user) {
                passedUser = user;
            } else {
                return;
            }
        }
        const _subscription = await getSubscription(passedUser.uid);
        setSubscription(_subscription);
        if (_subscription) {
            registerPosthogEvent(EVENT_NAME.setSubscriptionTier, {
                group,
                email: passedUser.email,
                subscriptionTier: _subscription.tier,
                isTrial: _subscription.isTrial,
                subscriptionValidThrough: _subscription.validThrough,
                subscriptionDaysRemaining: _subscription.daysRemaining,
                $set: {
                    subscriptionTier: _subscription.tier,
                    isTrial: _subscription.isTrial,
                    subscriptionValidThrough: _subscription.validThrough,
                    subscriptionDaysRemaining: _subscription.daysRemaining
                }
            });
        }
    };

    const fetchAndUpdateCmsIntegrationSettings = async () => {
        if (project) {
            try {
                const _cmsIntegrationSettings = await getAllCmsIntegrationSettingsWithNarrationSettingsByProject(project.id);

                const _rssIntegrationSettings = filterRssIntegrationSettingsFromCmsIntegrationSettings(_cmsIntegrationSettings);
                if (_rssIntegrationSettings) {
                    setRssIntegrationSettings(_rssIntegrationSettings);
                } else {
                    setRssIntegrationSettings([]);
                }

                const _hubspotIntegrationSettings = filterHubspotIntegrationSettingsFromCmsIntegrationSettings(_cmsIntegrationSettings);
                if (_hubspotIntegrationSettings) {
                    setHubspotIntegrationSettings(_hubspotIntegrationSettings);
                } else {
                    setHubspotIntegrationSettings([]);
                }
            } catch (error) {
                setRssIntegrationSettings([]);
                setHubspotIntegrationSettings([]);
            }
        }
    };
    useEffect(() => {
        if (!rssIntegrationSettings) {
            fetchAndUpdateCmsIntegrationSettings();
        }
    }, [project, rssIntegrationSettings]);

    const fetchAndUpdatePodcastChannel = () => {
        if (project) {
            getPodcastChannel(project.id)
                .then((channel) => {
                    setPodcastChannel(channel);
                })
                .catch((err) => {
                    const { message, cause } = formatSentryError('Error getting podcastChannel', 'AuthHook', err);
                    captureException(new Error(message, cause));
                });
        }
    };
    useEffect(() => {
        if (project) {
            fetchAndUpdatePodcastChannel();
        }
    }, [project]);

    const fetchAndUpdateProject = async (attemptCount = 0) => {
        if (attemptCount < 5) {
            const _project = await getProjectByGroupId(group);
            if (_project) {
                setProject(_project);
            } else {
                // * recursively re-call the function in case project hasn't been creted yet
                setTimeout(() => {
                    fetchAndUpdateProject(attemptCount + 1);
                }, 500);
            }
        }
    };

    // TODO: fix the double call setting setNarrationSettings since it's already being set in setCoreWorkspaceDocuments
    useEffect(() => {
        fetchAndUpdateNarrationSettings();
    }, [group]);

    const fetchAndUpdateNarrationSettings = async (attemptCount = 0) => {
        if (!group || !group.id) return;

        if (attemptCount < 5) {
            try {
                const allNarrationSettingsDocs = await getAllNarrationSettingsByGroup(group.id);
                if (allNarrationSettingsDocs) {
                    setAllNarrationSettings(allNarrationSettingsDocs);
                    getAndSetNarrationSettingsForGroup(group, setGroup, allNarrationSettingsDocs, setNarrationSettings);
                } else {
                    // * recursively re-call the function in case narrationSettings hasn't been creted yet
                    setTimeout(() => {
                        fetchAndUpdateNarrationSettings(attemptCount + 1);
                    }, 500);
                }
            } catch (error) {
                // * recursively re-call the function in case narrationSettings hasn't been creted yet
                setTimeout(() => {
                    fetchAndUpdateNarrationSettings(attemptCount + 1);
                }, 500);
            }
        } else {
            setNarrationSettings(null);
            const message = formatSentryMessage('Error fetching narration settings', 'fetchAndUpdateNarrationSettings');
            captureException(new Error(message));
        }
    };

    const fetchAndUpdateGroup = async (attemptCount = 0) => {
        if (attemptCount < 5) {
            const group = await getGroupByUid(user, attemptCount);
            if (group) {
                setGroup(group);
                setIsLoadingNarrationUsage(false);
            } else {
                // * recursively re-call the function in case group hasn't been created yet, with an increasing delay
                setTimeout(() => {
                    fetchAndUpdateGroup(attemptCount + 1);
                }, 500 * attemptCount);
            }
        } else {
            setIsLoadingNarrationUsage(false);
            const message = formatSentryMessage('Error fetching group', 'fetchAndUpdateGroup');
            captureException(new Error(message));
        }
    };

    const setCoreWorkspaceDocuments = async (adminSelectedUid?: string) => {
        const uid = adminSelectedUid || user?.uid;
        if (uid) {
            try {
                const coreWorkspaceDocuments = await getOrCreateCoreWorkspaceDocuments(uid);
                if (coreWorkspaceDocuments) {
                    setGroup(coreWorkspaceDocuments.group);
                    setIsLoadingNarrationUsage(false);
                    setProject(coreWorkspaceDocuments.project);
                    setNarrationSettings(coreWorkspaceDocuments.narrationSettings);

                    if (!adminSelectedUid) {
                        // * init telemetry with core account documents
                        identifyPosthogGroup(coreWorkspaceDocuments.group);
                    }
                }
            } catch (error) {
                const { message, cause } = formatSentryError('Error setting core workspace documents', 'AuthHook', error);
                captureException(new Error(message, cause));

                // * also log this key failure to posthog so that we can track it in terms of retention and funnels
                registerPosthogEvent(EVENT_NAME.auth_errorSettingCoreWorkspaceDocuments, {
                    user,
                    error
                });
            }
        }
    };

    // * set core account documents on user change.
    // * this will also create the core account documents if they don't exist (new users).
    useEffect(() => {
        setCoreWorkspaceDocuments();
    }, [user]);

    // * recursively check for group updates after a credit update
    const fetchAndUpdateGroupAfterCreditUpdate = async (attemptCount = 0) => {
        try {
            // * for older groups that don't have the narrationUsage property, don't need to update
            if (!group?.narrationUsage) {
                return;
            }
            // * don't need to reset every recursive call if the loading state is already set
            if (!isLoadingNarrationUsage) {
                setIsLoadingNarrationUsage(true);
            }
            // * try 10 times with 500ms delay each time, so 5 seconds total
            if (attemptCount < 10) {
                const _group = await getGroupByUid(user, attemptCount);
                // * if narrationUsage has changed, update group
                if (!isDeepEqual(group?.narrationUsage, _group?.narrationUsage)) {
                    setGroup(_group);
                    setIsLoadingNarrationUsage(false);
                } else {
                    // * recursively re-call the function in case group hasn't been creted yet
                    setTimeout(() => {
                        fetchAndUpdateGroupAfterCreditUpdate(attemptCount + 1);
                    }, 500); // * don't increase delay since it's not failure related and we want to update as soon as possible
                }
            } else {
                setIsLoadingNarrationUsage(false);
                const message = formatSentryMessage('Error fetching group after credit update', 'fetchAndUpdateGroupAfterCreditUpdate');
                captureException(new Error(message));
            }
        } catch (error) {
            // * additional catch will stop the spinner in case an unexpected error occurs
            setIsLoadingNarrationUsage(false);
            const { message, cause } = formatSentryError('Error fetching group after credit update', 'fetchAndUpdateGroupAfterCreditUpdate', error);
            captureException(new Error(message, cause));
        }
    };

    // * suggest an rss feed if there isn't a configured cmsIntegration
    useEffect(() => {
        updateSuggestedRssFeed(rssIntegrationSettings, group, suggestedRssFeed, setSuggestedRssFeed);
    }, [group, suggestedRssFeed, rssIntegrationSettings]);

    const fetchAndUpdateHosts = async () => {
        if (group) {
            try {
                const hosts = await getHostsByGroupId(group.id);
                setHosts(hosts);
            } catch (error) {
                setHosts(undefined);
            }
        }
    };

    useEffect(() => {
        fetchAndUpdateHosts();
    }, [group?.id]);

    // run after user change
    const onAuthStateChangedHandler = (user: User | null) => {
        // * reset the state vars that are dependent on user in case the user changes without logging out
        resetUserDependentState();

        setUser(user);

        if (user) {
            // * init telemetry immediately
            try {
                initTelemetryUser(user);
            } catch (e) {
                const { message, cause } = formatSentryError('Error setting initial telemetry users', 'Authhook.tsx', e);
                captureException(new Error(message, cause));
            }

            getAndSetSubscription(user);

            try {
                // * set uid in auth cookie for posthog
                document.cookie = formatAuthCookie({ uid: user.uid });
            } catch (e) {
                const { message, cause } = formatSentryError('Error formatting auth cookie', 'AuthHook.tsx', e);
                captureException(new Error(message, cause));
            }
        }

        // set loading to false once the user is done being set
        setLoading(false);
    };

    // initial page load
    useEffect(() => {
        try {
            // set listener - note onAuthStateChanged runs on page load
            const unsubscribeOnAuthStateChanged = onAuthStateChanged(auth, (user) => {
                // * this should only run once since loading gets set to false afterwards
                onAuthStateChangedHandler(user);
            });

            // remove listener
            return () => unsubscribeOnAuthStateChanged();
        } catch (error: unknown) {
            captureException(new Error('Error creating onAuthStateChanged listener - via AuthHook', { cause: error as Error }));
        }
    }, []);

    const signOut = async () => {
        setLoading(true);
        registerPosthogEvent(EVENT_NAME.logOut, {
            user,
            project,
            group
        });
        await fSignOut(auth)
            .catch((error) => {
                // An error happened.
                captureException(new Error('Error logging out - via AuthHook.tsx', { cause: error as Error }));
            })
            .finally(() => {
                resetUserDependentState();
                setLoading(false);
            });
    };

    // * Call after user login or signup. Does not inlcude page loads where the user is already authenticated
    const postLogin = async (userCredential: UserCredential) => {
        const user = userCredential.user;

        let _isNewUser = false;
        const additionalInfo = getAdditionalUserInfo(userCredential);
        if (additionalInfo) {
            _isNewUser = additionalInfo.isNewUser;
        } else {
            const message = formatSentryMessage('Error getting additional user info during new user check', 'postLogin');
            captureException(new Error(message));
        }
        setIsNewUser(_isNewUser);
        if (_isNewUser) {
            registerTwitterEvent('tw-omyrh-omyty', {
                email_address: user.email,
                phone_number: user.phoneNumber
            });
            registerGoogleAnalyticsSignupEvent();
        }

        const isComingFromHubspotIntegration = checkIfHubspotDataCookieExists();
        const hasPromoCode = checkIfPromoCookieExists();

        const posthogEventName = _isNewUser ? EVENT_NAME.signup : EVENT_NAME.signin;
        registerPosthogEvent(posthogEventName, { ...user, ...userCredential, ...additionalInfo, isComingFromHubspotIntegration });

        if (_isNewUser && hasPromoCode) {
            // * redirect to plan selection page if new user and has promo code
            router.push(ALL_ROUTES.SETTINGS);
        } else if (isComingFromHubspotIntegration) {
            router.push(ALL_ROUTES.HUBSPOT_SUCCESS);
        } else {
            router.push(ALL_ROUTES.HOME);
        }
    };

    const registerPosthogEventWithAuthProps = (eventName: EVENT_NAME, extraProperties?: Record<string, any>) => {
        const properties = {
            currentLocation: window.location.href, // ! previously used for some insights but has been replaced by the default posthog property $current_url in postHogEventHelpers. Should be removed if unneeded in insights.
            group,
            project,
            user,
            subscriptionTier: subscription?.tier, // * this is just the stripe tier and does not include plan statuses like Tutorial, Enterprise, etc, which are calculated in FeatureFlagsHook.
            ...extraProperties
        };
        registerPosthogEvent(eventName, properties);
    };

    const updateUserDisplayName = async (displayName: string) => {
        if (user) {
            try {
                // * updates firebase but doesn't trigger onAuthStateChanged or update local state user object,
                // * so it would require a refresh to see the change unless we manually update the user object
                await updateProfile(user, {
                    displayName
                });

                // * manually update user object
                setUser({
                    ...user,
                    displayName
                });
                // * init telemetry again with updated user
                // * no need to set posthog property here since it gets handled in initTelemetryUser
                try {
                    initTelemetryUser(user);
                } catch (e) {
                    const { message, cause } = formatSentryError('Error setting telemetry users after updating display name', 'Authhook.tsx', e);
                    captureException(new Error(message, cause));
                }
            } catch (error) {
                const { message, cause } = formatSentryError('Error updating user displayName', 'authHook', error);
                captureException(new Error(message, cause));
                throw error;
            }
        }
    };

    const resetUserDependentState = () => {
        // * reset all state vars
        setUser(null);
        setIsNewUser(false);
        setGroup(null);
        setProject(null);
        setSubscription(null);
        setPodcastChannel(null);

        // * undefined means unchecked for user
        setRssIntegrationSettings(undefined);
        setHubspotIntegrationSettings(undefined);

        setNarrationSettings(undefined);
        setSuggestedRssFeed(undefined);

        deleteAuthCookie();
        Sentry.setUser(null);
        posthog.reset();
    };

    return {
        user,
        project,
        group,
        isNewUser,
        setIsNewUser,
        subscription,
        loading,
        signOut,
        postLogin,
        podcastChannel,
        rssIntegrationSettings,
        hubspotIntegrationSettings,
        narrationSettings,
        allNarrationSettings,
        hosts,
        fetchAndUpdateHosts,
        fetchAndUpdatePodcastChannel,
        fetchAndUpdateNarrationSettings,
        fetchAndUpdateCmsIntegrationSettings,
        fetchAndUpdateProject,
        fetchAndUpdateGroup,
        fetchAndUpdateGroupAfterCreditUpdate,
        isLoadingNarrationUsage,
        registerPosthogEventWithAuthProps,
        updateUserDisplayName,
        setCoreWorkspaceDocuments,
        suggestedRssFeed
    };
}
