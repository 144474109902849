export const ADAURIS_PLAY_LINK = 'https://play.ad-auris.com?utm_source=b2b-dashboard&utm_medium=internal';
export const RSS_INSTRUCTIONS_LINK =
    'https://docs.google.com/document/d/e/2PACX-1vR0ziyhP80NB1mnQnwDNxGlVKWlXn9f2PlEd0Mbau5NJUS_6MSvc5Yr9M0MdjhYZR_-_T0U-yseD9il/pub?embedded=true';
export const WORDPRESS_INSTRUCTIONS_LINK = 'https://play-auris.notion.site/WordPress-Guide-How-to-embed-iframe-ae67712ae18e46ae86c284b59de3e8d6';
export const FINAL_ONBOARDING_PAGE = '/onboarding/welcome';
export const GOOGLE_ADD_RSS_FEED_URL = 'https://podcasts.google.com/subscribe-by-rss-feed';

export enum APP_ENV {
    development = 'development',
    test = 'test',
    staging = 'staging',
    production = 'production'
}

export const WHATS_APP_PHONE_NUMBER_WITH_DASHES = '1-548-457-1235';
export const WHATS_APP_PHONE_NUMBER = '15484571235';

// * UIDs from firebase
export const LANDING_PAGE_UID = process.env.APP_ENV === APP_ENV.production ? 's26ouIqf6JNDOIQQ8Z78cfg6Yqi2' : '2I1Z8l3G8DO5OB1PL5Ff2dXw9e93';
export const LANDING_PAGE_EMAIL = 'logan.underwood+landingpage@ad-auris.com';

export const NARRATIONS_PER_PAGE_LIMIT = 5;

// * taken from splash page app for narration sharing components
export const CALENDLY_SECTION_ID = 'book-a-demo';
export const CALENDLY_LEARN_MORE_URL = 'https://calendly.com/tina-haertel/productdemo?hide_gdpr_banner=1';
export const CALENDLY_CONSULTATION_URL = 'https://calendly.com/tina-haertel/consultation?hide_gdpr_banner=1';

export const ADAURIS_DASHBOARD_LOGIN_URL = 'https://business.adauris.ai';

export const ADAURIS_EMAIL_DOMAIN = '@ad-auris.com';
export const ADAURIS_TEST_EMAIL_DOMAIN = '@test.com';

export const MULTIPLE_LANGUAGES_OPTION_NAME = 'Multiple Languages *';

export const YOUTUBE_SUPADEMO_GUIDE_LINK = 'https://app.supademo.com/demo/QyvORzDHn8BDZdRGC0FPl';

export const DISTRIBUTION_PAGE_QUERY_PARAM_FOR_TAB_INDEX = 'tab_index';

// * this is currently set as the siteURL within the group in the account-creation cloud function
export const DEFAULT_GROUP_SITE_URL = 'https://www.mygroup.com';

export const CANNY_URL = 'https://adauris.canny.io';

export const HUBSPOT_COOKIE_NAME = 'hubspotData';

export const DISALLOWED_TEMPLATE_NAMES = ['Default', 'Group', 'Integration'];
