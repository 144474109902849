import { captureException } from '@sentry/nextjs';
import {
    AuthContextInterface,
    FirestoreDocumentIdType,
    GlobalNarrationContextInterface,
    NarrationInterface,
    NarrationPostPayloadInterface
} from '../types/types';
import { ALL_PROMO_COOKIES_VALUES } from '../utils/constants/campaignConstants';
import { createNarrationDocument } from './firestoreHelpers';
import { getNarrationIdFromSharePageUrl } from './narrationHelpers';
import { EVENT_NAME } from '../utils/constants/postHogConstants';

export const getSingleCookie = (cookie: string | undefined, key: string) => {
    let parsedCookie: any = undefined;

    if (cookie) {
        const crumbs: string[] = cookie.split('; ');

        crumbs.forEach((crumb: string) => {
            const crushedCrumb: string[] = crumb.split('=');

            if (crushedCrumb[0] === key) {
                if (typeof crushedCrumb[1] === 'object') {
                    parsedCookie = JSON.parse(crushedCrumb[1]);
                } else {
                    parsedCookie = crushedCrumb[1];
                }
            }
        });

        return parsedCookie;
    }
};
export const deleteAuthCookie = () => {
    document.cookie = 'auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    // * delete annonymous_uuid because it gets set as the firebase UID once a user logs in
    document.cookie = 'anonymous_uuid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const getDateInOneWeek = () => {
    const oneWeekInMilliseconds = 1000 * 60 * 60 * 24 * 7;
    return new Date(new Date().getTime() + oneWeekInMilliseconds);
};

export const getDateStringInOneWeek = () => {
    const oneWeekInMilliseconds = 1000 * 60 * 60 * 24 * 7;

    return new Date(new Date().getTime() + oneWeekInMilliseconds).toUTCString();
};

export const getDateStringInFourWeeks = () => {
    const fourWeeksInMilliseconds = 1000 * 60 * 60 * 24 * 7 * 4;
    return new Date(new Date().getTime() + fourWeeksInMilliseconds).toUTCString();
};

export const formatAuthCookie = (cookieBody: Record<string, any>) => {
    return 'auth=' + JSON.stringify(cookieBody) + ';Path=/;expires=' + getDateStringInFourWeeks() + ';';
};

export const checkIfPromoCookieExists = () => {
    for (const cookieValue of ALL_PROMO_COOKIES_VALUES) {
        if (document.cookie.includes(cookieValue)) {
            return true;
        }
    }
    return false;
};

export const deleteCookie = (cookieName: string) => {
    document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

interface copyNarrationFromCookieProps {
    cookieName: string;
    projectId: FirestoreDocumentIdType;
    mutateNarrations: GlobalNarrationContextInterface['mutateNarrations'];
    registerPosthogEventWithAuthProps: AuthContextInterface['registerPosthogEventWithAuthProps'];
    toast: any;
    fetchAndUpdateGroupAfterCreditUpdate: AuthContextInterface['fetchAndUpdateGroupAfterCreditUpdate'];
}
export const copyNarrationFromCookie = async ({
    cookieName,
    projectId,
    mutateNarrations,
    registerPosthogEventWithAuthProps,
    fetchAndUpdateGroupAfterCreditUpdate,
    toast
}: copyNarrationFromCookieProps) => {
    const narrationShareUrl = getSingleCookie(document.cookie, cookieName);
    if (narrationShareUrl) {
        try {
            const narration = (await getNarrationIdFromSharePageUrl(narrationShareUrl)) as Partial<NarrationInterface>;

            // * copy narration with new projectId
            narration.projectId = projectId;
            delete narration.id;
            delete narration.creationDate;
            delete narration.modifiedDate;

            const narrationPayload = narration as NarrationPostPayloadInterface;
            const narrationId = await createNarrationDocument(narrationPayload, mutateNarrations);
            deleteCookie(cookieName);
            registerPosthogEventWithAuthProps(EVENT_NAME.settings_copyNarrationFromCookie, {
                narration,
                newNarrationId: narrationId
            });
            toast({
                title: 'Narration added to your Audio Catalogue',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            });
            fetchAndUpdateGroupAfterCreditUpdate();
        } catch {
            console.error('Failed to copy narration from cookie');
            captureException('Failed to copy narration from cookie');
        }
    }
};
