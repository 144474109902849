// * This container allows us to access come context variables within _app level

import { ChakraProvider, ColorModeScript, createLocalStorageManager } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import { AuthProvider } from '../../context/AuthContext';
import theme from '../../themes/theme';
import AppGlobalNarrationProviderContainer from './_appGlobalNarrationProviderContainer';

const chakraLocalStorageKey = 'adauris-dashboard-color-mode';

function AppMultiProviderContainer(appProps: AppProps) {
    /* 
    This is a custom chakra color mode manager that syncs with local storage.
    It tells our application to ignore the localstorage var chakra-ui-color-mode and instead set/get the localstorage var adauris-dashboard-color-mode.
    When we switched from light mode to dark mode, chakra-ui-color-mode was still saved as light mode in localstorage for all users that didn't clear their localstorage.
    chakra-ui-color-mode will still be set as light for these users, but they will now access adauris-dashboard-color-mode for this value instead and will now see dark mode.
    If we ever need to change the default theme value again, then we will need to update the chakraLocalStorageKey value to a new key.

    See here for context from the Chakra team: https://github.com/chakra-ui/chakra-ui/issues/5554
    See here for our internal investigation/reporting on the bug: https://linear.app/ad-auris/issue/ENG-672/contrast-of-certain-components-is-off-on-safari
    */
    const manager = createLocalStorageManager(chakraLocalStorageKey);

    return (
        <AuthProvider>
            <NextNProgress color="#82b401" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow={true} />
            <ChakraProvider theme={theme} colorModeManager={manager}>
                <ColorModeScript initialColorMode={theme.config.initialColorMode} storageKey={chakraLocalStorageKey} />
                <AppGlobalNarrationProviderContainer {...appProps} />
            </ChakraProvider>
        </AuthProvider>
    );
}

export default AppMultiProviderContainer;
